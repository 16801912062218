<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
const mouse = {
  x: innerWidth + 20,
  y: innerHeight - innerHeight / 4,
};
addEventListener("mousemove", (event) => {
  mouse.x = event.clientX;
  mouse.y = event.clientY;
});

function Particle(x, y, radius, color) {
  const distance = randomIntFromRange(80, 280);
  this.x = x;
  this.y = y;
  this.radius = radius;
  this.color = color;
  this.radians = Math.random() * Math.PI * 2;
  this.velocity = 0.01;
  this.distanceFromCenter = {
    x: distance,
    y: distance,
  };
  this.prevDistanceFromCenter = {
    x: distance,
    y: distance,
  };
  this.lastMouse = {
    x: x,
    y: y,
  };

  this.setContext = (context) => {
    this.c = context;
  };

  this.update = () => {
    const lastPoint = {
      x: this.x,
      y: this.y,
    };
    // Move points over time
    this.radians += this.velocity;

    // Drag effect
    this.lastMouse.x += (mouse.x - this.lastMouse.x) * 0.05;
    this.lastMouse.y += (mouse.y - this.lastMouse.y) * 0.05;

    // Circular Motion
    this.distanceFromCenter.x =
      this.prevDistanceFromCenter.x + Math.sin(this.radians) * 100;
    this.distanceFromCenter.y =
      this.prevDistanceFromCenter.x + Math.sin(this.radians) * 100;

    this.x =
      this.lastMouse.x + Math.cos(this.radians) * this.distanceFromCenter.x;
    this.y =
      this.lastMouse.y + Math.sin(this.radians) * this.distanceFromCenter.y;

    this.draw(lastPoint);
  };

  this.draw = (lastPoint) => {
    // this.c.beginPath();
    // this.c.strokeStyle = this.color;
    // this.c.lineWidth = this.radius;
    // this.c.moveTo(lastPoint.x, lastPoint.y);
    // this.c.lineTo(this.x, this.y);
    // this.c.stroke();
    // this.c.closePath();

    this.c.beginPath();
    this.c.arc(lastPoint.x, lastPoint.y, this.radius, 0, Math.PI * 2, false);
    this.c.fillStyle = this.color;
    this.c.fill();
    this.c.closePath();
  };
}

function randomIntFromRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default {
  data() {
    return {
      particles: [],
      colors: ["#6c4a9e", "#c04c9c", "#df095b", "#f00d76"],
      canvas: null,
      c: null,
    };
  },

  methods: {
    // Setup
    init() {
      this.particles = [];
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;

      for (let i = 0; i < 100; i++) {
        const radius = Math.random() * 4 + 4;
        this.particles.push(
          new Particle(
            this.canvas.width / 2,
            this.canvas.height / 2,
            radius,
            this.randomColor(this.colors)
          )
        );
      }

      console.log(this.particles);
    },

    // Animation
    animate() {
      requestAnimationFrame(this.animate);
      this.c.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.particles.forEach((p) => {
        p.setContext(this.c);
        p.update();
      });
    },
    // Colors
    randomColor(colors) {
      return colors[Math.floor(Math.random() * colors.length)];
    },
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.c = this.canvas.getContext("2d");

    this.init();
    this.animate();

    addEventListener("resize", () => {
      console.log("resizing");
      this.canvas.width = innerWidth;
      this.canvas.height = innerHeight;

      this.init();
    });

    addEventListener("orientationchange", () => {
      console.log("changed orientation");
      this.canvas.width = innerWidth;
      this.canvas.height = innerHeight;

      this.init();
    });
  },
};
</script>

<style>
</style>