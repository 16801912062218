<template>
  <div>
    <section
      class="pt-24 md:pt-0 w-screen h-screen flex items-center bg-gray-300"
      id="particles-js"
    >
      <div class="">
        <div class="flex flex-col mx-4 md:mx-8">
          <router-link
            to="/crm"
            class="text-5xl md:text-8xl font-extrabold uppercase mb-8"
            >EXPERIENCE BUILDERS</router-link
          >
          <p class="text-2xl md:text-4xl font-bold">
            Creating immersive & interactive experiences through Web, Apps & VR
          </p>
        </div>
        <div class="text-right mx-4 md:mx-8">
          <i class="far fa-chevron-down fa-7x"></i>
        </div>
      </div>
    </section>
    <section
      class="
        pt-12
        md:pt-0 md:w-screen md:h-screen
        flex
        items-center
        fc-pink
        text-white
      "
    >
      <div class="mx-4 md:mx-8">
        <div class="text-4xl md:text-8xl font-extrabold uppercase mb-8">
          The Collective
        </div>
        <div class="flex flex-col md:flex-row my-12 items-center">
          <div class="w-full md:w-1/2 mb-8 md:mb-0 mr-0 md:mr-12">
            <p class="text-xl text-white mb-4">
              We are a collective of digital specialists, Combining our skills
              as developers, strategists, social gurus, designers and humans, we
              create awesome experiences in VR, real life and for businesses.
            </p>

            <p class="text-xl text-white">
              Metaverse social meet-ups to immersive games, to payment gateways,
              to custom software, to dashboards &amp; portals. Our wide range of
              skills cover everything from idea to production ready deliverables
              with tailored branding, social and marketing teams helping you to
              release your idea or project.
            </p>
          </div>

          <div class="w-full md:w-1/2 flex items-center justify-center">
            <img
              src="@/assets/images/pages/startup.png"
              class="mb-8"
              alt="Friday Collective"
              width="75%"
            />
          </div>
        </div>
      </div>
    </section>
    <section
      class="
        pt-12
        md:pt-0 md:w-screen md:h-screen
        fc-purple
        flex
        items-center
        text-white
      "
    >
      <div class="mx-4 md:mx-8">
        <div class="text-4xl md:text-8xl font-extrabold uppercase mb-8">
          Portals
        </div>
        <div class="flex flex-col md:flex-row my-12 items-center">
          <div class="w-full md:w-1/2 mb-8 md:mb-0">
            <p class="text-xl text-white mb-4">
              Friday Collective specialise in creating intuitive, user friendly
              and beautiful relationship management portals, dashboards,
              interactive web experiences and packages. Using the latest in web
              tech to deliver products and projects used by thousands of users
              around the world.
            </p>

            <p class="text-xl text-white mb-4">
              Bringing together decades of experience we built Crank CRM from
              the ground up, delivering an sustainable, flexible and cost
              efficient CRM for small businesses.
            </p>

            <p class="text-xl text-white">
              Do you have an idea, a product or a project? Are you looking for
              experience, attention to detail and creativity or simply need a
              Special Forces squad of developers to help deliver an existing
              project?
            </p>
          </div>

          <div class="w-full md:w-1/2">
            <div class="flex flex-col">
              <div class="ml-auto">
                <img
                  src="@/assets/images/crank/dashboard.png"
                  alt="Crank CRM"
                  class="rounded-xl mb-8"
                />
              </div>
              <div class="text-right">
                <a
                  href="https://crankcrm.com"
                  target="_blank"
                  class="
                    border border-white
                    rounded-lg
                    py-2
                    px-3
                    text-white
                    font-bold
                    hover:bg-white hover:text-pink-600 hover:border-0
                    mr-4
                  "
                  >Visit Crank CRM</a
                >

                <a
                  href="https://crankcrm.com"
                  target="_blank"
                  class="
                    bg-white
                    rounded-lg
                    py-2
                    px-3
                    fc-text-pink
                    font-bold
                    hover:bg-pink-600 hover:text-white hover:text-purple
                  "
                  >Find out more</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-24">
      <div class="flex flex-col px-8">
        <div class="text-4xl md:text-8xl font-bold fc-text-pink uppercase mb-8">
          Our work
        </div>
        <div class="">
          A healthcare platform for clinics and their staff to manage patients,
          records, questionnaires, tests, product ordering, video consultations
          and much more.
        </div>
      </div>
      <div class="flex flex-row bg-white">
        <div class="flex flex-col md:flex-row justify-between">
          <div class="m-8 md:m-12">
            <img
              src="@/assets/images/portfolio/swandoola/desktop-1.png"
              alt=""
              class="rounded-xl"
            />
          </div>
          <div class="p-8">
            A companion mobile application for patients to record health
            information, place orders and attend video consultations
          </div>
          <div class="m-8 md:m-12">
            <img
              src="@/assets/images/portfolio/swandoola/app-1.png"
              alt=""
              class="rounded-xl"
            />
          </div>
          <div class="m-8 md:m-12">
            <img
              src="@/assets/images/portfolio/swandoola/app-2.png"
              alt=""
              class="rounded-xl"
            />
          </div>
          <div class="m-8 md:m-12">
            <img
              src="@/assets/images/portfolio/swandoola/app-3.png"
              alt=""
              class="rounded-xl"
            />
          </div>
        </div>
      </div>
    </section>
    <section
      class="
        pt-12
        md:pt-0 md:w-screen md:h-screen
        bg-black
        flex
        items-center
        text-white
      "
    >
      <div class="mx-4 md:mx-8">
        <div class="text-4xl md:text-8xl font-extrabold uppercase mb-8">
          VR &amp; Metaverse
        </div>
        <div class="flex flex-col md:flex-row my-12 items-center">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div class="flex items-center">
              <div>
                <p class="text-xl text-white mb-4">
                  Creating totally immersive, engaging applications and games
                  for VR started out as a passion driven break from everyday
                  development, what started out as a cool hobby has fast turned
                  into an entire division of Friday Collective.
                </p>

                <p class="text-xl text-white">
                  Multiplayer, feature rich, social and business applications
                  that work across multiple platforms will be the future we grow
                  to take for granted, Friday Collective is at the bleeding edge
                  of technical advancements in the VR and metaverse space.
                </p>
              </div>
            </div>

            <div class="">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <img
                  src="@/assets/images/meta/island.jpg"
                  class="w-full rounded-xl mb-8"
                  alt="Friday Arcade"
                />
                <img
                  src="@/assets/images/meta/erys.jpg"
                  class="w-full rounded-xl"
                  alt="Erys"
                />
                <img
                  src="@/assets/images/meta/erys-2.jpg"
                  class="w-full rounded-xl"
                  alt="Erys"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="
        pt-12
        md:pt-0 md:w-screen md:h-screen
        fc-purple
        flex
        items-center
        text-white
      "
    >
      <div class="mx-4 md:mx-8">
        <div class="text-8xl font-extrabold uppercase mb-8">HOLLA</div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="flex items-center mb-8 md:mb-0">
            <div>
              <p class="text-2xl md:text-4xl font-bold text-white mb-4">
                Drop us a line
              </p>
              <p class="text-xl md:text-2xl font-extrabold text-white">
                hey at fridaycollective.co.uk
              </p>
            </div>
          </div>

          <div class="flex flex-col md:text-right">
            <div class="mb-4">
              <a href="" class="text-white font-bold">Crank CRM</a>
            </div>
            <div class="mb-4">
              <a href="" class="text-white font-bold">MV1</a>
            </div>
            <div class="mb-4">
              <a href="" class="text-white font-bold">Erys</a>
            </div>
            <div class="mb-4">
              <a href="" class="text-white font-bold">Friday Arcade</a>
            </div>
            <div class="mb-4">
              <a href="" class="text-white font-bold">Pay with Friday</a>
            </div>
            <div class="mb-4">
              <a href="" class="text-white font-bold">Connect</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MovingBackground from "./home-mixins/MovingBackground";
import "particles.js";

export default {
  name: "Home",
  created() {
    // document.body.classList.add("bg-black");
    // document.addEventListener("mousemove", this.fill);
  },
  methods: {
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 35,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#f00d76",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000",
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 11.83721462448409,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 4,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 400.8530152163807,
              rotateY: 1122.388442605866,
            },
          },
        },
        interactivity: {
          detect_on: "window",
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    fill(e) {
      this.$refs.filler.style.height = e.offsetY + "px";
      this.$refs.filler.style.background = "#fff";

      console.log(this.$refs.filler.style.width);
    },
  },
  beforeDestroy() {
    // document.body.classList.remove("bg-black");
  },
  mounted() {
    this.initParticles();
  },
  components: {
    MovingBackground,
  },
};
</script>

<style lang="scss">
canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.gradient-card {
  background: #12c2e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f64f59,
    #c471ed,
    #12c2e9
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f64f59,
    #c471ed,
    #12c2e9
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.header-spacing {
  letter-spacing: 0.5rem;
}

.filler {
  background: white;
  height: 0;
  transition: width 0.2s;
  width: 100%;
}

.dark-gradient {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border: 1px solid #4d4d4d !important;
}
section {
  overflow: hidden;
}
</style>
